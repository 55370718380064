
import { Options, Vue } from 'vue-class-component';
import { ExperienceAffinityClient, ExperienceClient } from '@/services/Services';
import CardShadowInfo from '../components/experience/cardShadowInfo.vue';
import bodymovin from 'lottie-web';
import application from '../components/experience/experienceListComponents/application.vue';
import * as OM from '@/Model';

@Options({
    components: {
        CardShadowInfo,
        application
    }
})
export default class MyApplications extends Vue {

    experienceAffinityList: OM.ExperienceAffinityDetailVM[] = [];
    selectedTab: string = "approved";

    created() {
        this.init();
    }

    init() {
        ExperienceAffinityClient.getMyApplications()
        .then(x => {
            this.experienceAffinityList = x;

            if(this.approved.length == 0 && this.waiting.length > 0)
                this.selectedTab = "waiting";
        })
    }

    mounted(){
        setTimeout(() => {
            var experienceLoop = bodymovin.loadAnimation({
                container: <any>this.$refs.experienceLoop,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/experience_loop.json',
            })

            experienceLoop.play();
        }, 300);
    }

    get approved() {
        return this.experienceAffinityList.filter(x => x.match.chatRoomIdentifier);
    }
    get waiting() {
        return this.experienceAffinityList.filter(x => !x.match.chatRoomIdentifier);
    }
}
